<template>
  <div class="authentication">
    <b-container class="login-card-container">
      <b-card class="auth-card">
        <form name="form" @submit.prevent="handleLogin">
          <!-- Email -->
          <div class="form-group">
            <label for="email">{{ $t("email") }}</label>
            <input
              v-model="login.email"
              v-validate="'required|min:3|max:50'"
              type="text"
              class="form-control"
              name="email"
            />
          </div>
          <!-- Password -->
          <div class="form-group">
            <label for="password">{{ $t("password") }}</label>
            <input
              v-model="login.password"
              v-validate="'required|max:50'"
              type="password"
              class="form-control"
              name="password"
            />
          </div>

          <!-- Submit  -->
          <div class="submit-button-container form-group">
            <b-button type="submit" block>{{ $t("signup") }}</b-button>
          </div>
        </form>
      </b-card>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      login: { email: "", password: "" },
    };
  },
  computed: {},
  methods: {
    async handleLogin() {
      await this.$store.dispatch("Auth/adminLogin", this.login);
      this.$router.push({ name: "feature-config" });
    },
  },
};
</script>
<style lang="scss">
.authentication {
  flex-grow: 1;
}
</style>
